body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter';
  direction: ltr;
  /* Default direction */
}

html[dir='rtl'] {
  direction: rtl;
  text-align: right;
}

input,
textarea,
select {
  direction: inherit;
  text-align: inherit;
}

html[dir='rtl'] input,
html[dir='rtl'] textarea,
html[dir='rtl'] select {
  direction: rtl;
  text-align: right;
}

html[dir='ltr'] input,
html[dir='ltr'] textarea,
html[dir='ltr'] select {
  direction: ltr;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  /* background-color: rgba(192, 192, 192, 0.473); */
}